<template>
    <div>
        <report :title="$route.name" :parameters="parameters" @refresh="getReportData()" :loading="loading" @exportReport="exportReport">
            <template v-slot:content>
                <v-row>
                    <v-col cols="5" offset="7">
                        <v-text-field v-model="filter" append-icon="mdi-magnify" label="Filter" single-line hide-details clearable></v-text-field>
                    </v-col>
                </v-row>
                
                <v-data-table :headers="headers" :items="rptData" :search="filter" :items-per-page="200">
                    <template v-slot:item.deliveredUnits="{ item }">
                        {{ formatNumber( item.deliveredUnits, 0 ) }}
                    </template>
                    <template v-slot:item.total="{ item }">
                        {{ formatMoney( item.total ) }}
                    </template>
                    <template v-slot:item.payments="{ item }">
                        {{ formatMoney( item.payments ) }}
                    </template>
                    <template v-slot:item.unitAvg="{ item }">
                        {{ formatMoney( item.unitAvg ) }}
                    </template>

                    <template v-slot:body.append="{ headers, items }">
                        <tr class="totals" v-if="items.length > 0">
                            <td v-for="(col, i) in headers" :key="i" :class="col.align == 'right' ? 'text-right subtitle-2' : 'subtitle-2'">
                                {{ summarize( col, i ) }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </template>
        </report>
    </div>
</template>

<script>
import Report from '@/components/Report.vue';
import { API } from '@/inc/api';
import moment from 'moment';

export default {
    data: () => ({
        parameters: [{ 
            type: 'date', 
            key: 'pdate', 
            label: 'Use Date', 
            value: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)], 
            visible: true, 
            icon: '' 
        }
        // ,{
        //     type: 'select', 
        //     key: 'selectStore', 
        //     label: 'Origin store', 
        //     value: { id: '%', text: 'All' }, 
        //     visible: true, 
        //     icon: '',
        //     options: [
        //         { id: '%', text: 'All' }, 
        //         { id: 3, text: '65 de Infanteria' }, 
        //         { id: 6, text: 'Arecibo' }, 
        //         { id: 9, text: 'Bayamon' }, 
        //         { id: 4, text: 'Caguas' }, 
        //         { id: 5, text: 'Catano' }, 
        //         { id: 8, text: 'Mayaguez' }, 
        //         { id: 7, text: 'Ponce' }, 
        //         { id: 2, text: 'San Juan' },  
        //         { id: 1, text: 'Web' },
        //         { id: 10, text: 'Winter Park' } 
        //     ]
        // }
        ],
        headers: [
            { text: 'Store', value: 'store', sortable: true, summarizeFn: '' },
            { text: 'Units', value: 'deliveredUnits', align: 'right', sortable: true, summarizeFn: 'sum', formatter: 'integer' },
            { text: 'Total', value: 'total', align: 'right', sortable: true, summarizeFn: 'sum', formatter: 'integer' },
            { text: 'Payments', value: 'payments', align: 'right', sortable: true, summarizeFn: 'sum', formatter: 'money' },
            { text: 'Unit Avg.', value: 'unitAvg', align: 'right', sortable: true, summarizeFn: 'avg', formatter: 'money' },
        ],
        rptData: [],
        filter: '',
        loading: false,
    }),
    methods: {
        async getReportData() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get(`/admin/rpt/rentdeliveredorders/${this.parameters[0].value[0]}/${this.parameters[0].value[1]}`); // + '/' + encodeURI( this.parameters[1].value.id )
                this.loading = false;

                if( res.data.status === false ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' )
                    return;
                }

                this.rptData = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        formatDate( date ) {
            let m = moment( date, 'YYYY-MM-DD HH:mm:ss' );
            return m.format( 'YYYY-MM-DD hh:mm a' );
        },
        summarize( col ) {
            let data = this.rptData;
            let result = 0;

            if( data.length == 0 )
                return;

            // Summarize data
            if( col.summarizeFn == 'sum') {
                result = data.reduce( function(a, b) {
                    return parseFloat(a) + parseFloat(b[col.value] == null ? 0 : b[col.value]);
                }, 0);
            }
            else if( col.summarizeFn == 'avg' && col.value == 'unitAvg') {
                let totalUnits = data.reduce( function(a, b) {
                    return parseFloat(a) + parseFloat(b['deliveredUnits'] == null ? 0 : b['deliveredUnits']);
                }, 0);
                let totalRevenue = data.reduce( function(a, b) {
                    return parseFloat(a) + parseFloat(b['payments'] == null ? 0 : b['payments']);
                }, 0);

                result = totalRevenue / totalUnits;
            }

            // Apply formatter
            switch( col.formatter ) {
                case 'money': return this.formatMoney( result );
                case 'integer': return this.formatNumber( result, 0 );
                case 'na': return result;
            }
        },
        exportReport( format ) {
            if( format == 'pdf' ) this.generatePdf();
            else if( format == 'csv' ) this.exportCsv();
        },
        generatePdf() {
            let data = this.createPdfArray( this.headers, this.rptData );
            this.exportPdf( this.headers, data );
        },
        exportCsv() {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += this.createCsvArray(this.headers, this.rptData).map(item => item.join(',')).join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", encodeURI( this.$route.name ) + ".csv");
            document.body.appendChild(link); // Required for FF

            link.click(); // This will download the data file named "my_data.csv".
        },
    },
    components: {
        Report
    }
}
</script>

<style scoped>
tr.totals td {
    background-color: #ececec;
    height: auto;
    border-top: 3px double #000;
}
</style>